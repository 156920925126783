<style lang='scss' scoped>
    .zhuLongCoin {
        margin: 0 auto;
        position: relative;
        width: 965px;
        height: 700px;
        background-color: #fff;
        padding: 0 20px 20px 20px;
    }

    .div_updatePwd li {
        position: relative;
        width: 100%;
        height: 46px;
        margin-top: 25px;
        text-align: left;
    }

    .span_color {
        display: inline-block;
        line-height: 46px;
        width: 110px;
        color: #999999;
        text-align: right;
        margin-right: 15px;
        font-size: 14px;
    }

    .span_tip_common {
        height: 46px;
        position: absolute;
        margin-left: 20px;
        font-size: 12px;
        width: 300px;
    }

    .span_tip_red {
        font-size: 14px;
        color: #ee2e2e;
        line-height: 46px;

    }

    .span_tip_grey {
        color: #999999;
    }

    .input {
        width: 302px;
        height: 46px;
        background-color: #ffffff;
        border: solid 1px #cfcfcf;
        padding-left: 25px;
        outline: none;
    }

    .div_updatePwd button {
        width: 90px;
        height: 30px;
        background-color: #ee2e2e;
        border-radius: 6px;
        margin-top: 57px;
        color: white;
        cursor: pointer;
        margin-left: 124px;
    }

    .btn_ok {
        background-color: #ee2e2e;
        border: 1px solid #ee2e2e;
        margin-right: 32px;
        margin-left: 28px;
    }

    .span_safe {
        width: 61px;
        height: 12px;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
    }

    .div_safe {
        height: 21px;
        margin-left: 124px;
        margin-top: 22px;
    }

    .span_safe_common {
        display: inline-block;
        width: 36px;
        height: 6px;
        margin-bottom: 2px;
    }

    .span_safe_0 {
        background-color: #dfdfdf;
    }

    .span_safe_1 {
        background-color: #f14f4f;
    }

    .span_safe_2 {
        background-color: #00a0e9;
    }

    .span_safe_3 {
        background-color: lightgreen;
    }
.bbbbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-right: 40px;
}
/deep/.myclass-title {
  width: auto !important;
}
</style>
<template>
  <div class="zhuLongCoin" style="height:864px">
    <div class="bbbbox">
      <p class="myclass-title">
        <span class="title-text">{{ tips }}记录
          <span v-if="resultCoin.result.count>0">({{ resultCoin.result.count }})</span>
        </span>
        <span class="title-border"></span>
      </p>
      <span v-if="agency_id==2" @click="jumpRule">查看{{ tips }}获取规则</span>
    </div>

    <el-table
      :data="tableData"
      style="width:100%"
      stripe
      :header-cell-style="tableHeaderColor"
    >
      <el-table-column
        label="日期"
        align="center"
      >
        <template slot-scope="scope">
          <span style="margin-left: 0">{{ scope.row.create_time }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="交易方式"
        align="center"
      >
        <template slot-scope="scope">
          <span style="margin-left: 0">{{ scope.row.type_name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="收入"
        align="center"
      >
        <template slot-scope="scope">
          <span style="margin-left: 0">{{ scope.row.table_pay_mode }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="支出"
        align="center"
      >
        <template slot-scope="scope">
          <span style="margin-left: 0">{{ scope.row.table_income }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="余额"
        align="center"
      >
        <template slot-scope="scope">
          <span style="margin-left: 0">{{ scope.row.sumscore }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="备注"
        align="center"
      >
        <template slot-scope="scope">
          <span style="margin-left: 0">{{ scope.row.reason }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        label="详情"
        width="132"
        align="center"
      >
        <template slot-scope="scope">
          <span style="margin-left: 0">{{ scope.row.table_detail }}</span>
        </template>
      </el-table-column> -->

    </el-table>
    <el-pagination
      background
      style="margin-top: 16px"
      layout="prev, pager, next"
      :page-size="12"
      :total="resultCoin.result.count"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>

</template>
<script>

import { getCoinLog, getRow } from '../../../api/mine'
import tool from '../../../utils/tools'
import Cookies from 'js-cookie'

export default {
  data() {
    return {
      agency_id: '',
      params: {
        page: '',
        limit: 12
      },
      tips: '',
      tableData: [],
      resultCoin: {
        msg: '',
        result: {
          list: [{
            _id: { $oid: '' },
            score: '',
            type: '',
            create_time: '',
            reason: '',
            sumscore: ''
          }],
          count: 0
        }
      }
    }
  },
  created() {
    this.agency_id = Cookies.get('agency_id') ?? ''
    this.params.page = 1
    this.getRow()
    this.getZhuLongCoinsLog(this.params)
  },
  methods: {
    getRow() {
      getRow({ type: 1 }).then(res => {
        if (res.errNo === 0) {
          const origin = window.location.origin
          // if (this.agency_id == 11 || origin.includes('zk468') || origin.includes('zkbpro')) {
          //   this.tips = '注考币'
          // } else {
            this.tips = res.result.name
          // }
        }
      })
    },
    handleCurrentChange(val) {
      this.params.page = val
      this.getZhuLongCoinsLog(this.params)
    },
    // 获取币记录
    getZhuLongCoinsLog(params) {
      getCoinLog(params).then((res) => {
        this.resultCoin.msg = res.msg
        this.resultCoin.result = res.result
        this.tableData = res.result.list
        const resultCoin = this.resultCoin.result.list
      })
    },
    // 修改table header的背景色
    tableHeaderColor() {
      return 'color: #020202;font-weight:600;font-size:14px;text-align:center'
    },
    jumpRule() {
      window.open('https://www.zhulong.com/bbs/d/32362219.html')
    }
  }
}
</script>

